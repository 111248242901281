import React from 'react'
import { List, Datagrid, TextField, DateField, ReferenceField } from 'react-admin'
import { DEFAULT_SORT, DEFAULT_PER_PAGE } from 'admin/utils/react-admin'

export const AgencyReferralsList = () => (
  <List sort={DEFAULT_SORT} perPage={DEFAULT_PER_PAGE}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="agency_name" />
      <TextField source="contact_name" />
      <TextField source="phone_number" />
      <TextField source="email" />
      <ReferenceField source="employee_id" reference="employees" link="show" label="Employee" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
)
