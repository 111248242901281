import React, { useEffect, useState } from 'react'
import { Card, CardContent } from '@mui/material'
import {
  List,
  Datagrid,
  DateInput,
  RadioButtonGroupInput,
  TextField,
  DateField,
  FilterList,
  FilterListItem,
  Form,
  EditBase,
  Title,
  SaveButton,
  Labeled,
  useEditContext,
  required,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  ReferenceField,
  SelectInput,
  TextInput,
  FormDataConsumer,
  useRedirect,
} from 'react-admin'
import { DEFAULT_SORT, DEFAULT_PER_PAGE } from 'admin/utils/react-admin'
import VerifiedIcon from '@mui/icons-material/Verified'
import AlarmIcon from '@mui/icons-material/Alarm'
import VisibilityIcon from '@mui/icons-material/Visibility'
import LockIcon from '@mui/icons-material/Lock'
import { DocumentVerificationStatus, UploadedDocument } from 'types/documents'
import DocumentViewer from 'components/DocumentViewer'
import Button from 'components/Button'
import { EmployeeFromEndpoint } from 'agency/types/employees'
import constants from 'utils/constants'
import i18next from 'i18next'
import DateTimeField from 'admin/components/DateTimeField'
import httpClient from 'utils/http-client'
import toast from 'react-hot-toast'

const REFETCH_INTERVAL = 10_000 // 10 seconds

const DocumentFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
    <CardContent>
      <FilterList label="Verification status" icon={<VerifiedIcon />}>
        <FilterListItem label="Required Manual" value={{ verification_status: 'required_manual' }} />
        <FilterListItem label="Required Auto" value={{ verification_status: 'required_auto' }} />
        <FilterListItem label="Non conforme" value={{ verification_status: 'non_compliant' }} />
        <FilterListItem label="Illisible" value={{ verification_status: 'unreadable' }} />
        <FilterListItem label="Compliant" value={{ verification_status: 'compliant' }} />
        <FilterListItem label="Frauduleux" value={{ verification_status: 'fraudulent' }} />
        <FilterListItem label="Nom manquant" value={{ verification_status: 'missing_name' }} />
        <FilterListItem label="Recto ou verso manquant" value={{ verification_status: 'missing_double_sided' }} />
        <FilterListItem label="Signature manquante" value={{ verification_status: 'missing_signature' }} />
        <FilterListItem label="Date manquante" value={{ verification_status: 'missing_date' }} />
        <FilterListItem label="Ok" value={{ verification_status: 'ok' }} />
      </FilterList>
      <FilterList label="Date d'expiration" icon={<AlarmIcon />}>
        <FilterListItem label="Expiré" value={{ expiration_date: 'expired' }} />
        <FilterListItem label="Pas expiré" value={{ expiration_date: 'ok' }} />
        <FilterListItem label="Manquante" value={{ expiration_date: 'missing' }} />
      </FilterList>
      <FilterList label="Visibilité" icon={<VisibilityIcon />} defaultValue="false">
        <FilterListItem label="Tout les documents" value={{ show_all: 'true' }} />
        <FilterListItem label="Uniquement les documents présent dans le dossier" value={{ show_all: 'false' }} />
      </FilterList>
      <FilterList label="Verifiable par CTMS" icon={<LockIcon />} defaultValue="false">
        <FilterListItem label="Oui" value={{ only_verifiable_by_ctms: 'true' }} />
        <FilterListItem label="Non" value={{ only_verifiable_by_ctms: 'false' }} />
      </FilterList>
    </CardContent>
  </Card>
)

type DocumentFromEndpoint = UploadedDocument & {
  created_at: string
  updated_at: string
  kind_slug: string
  verifiable_by_ctms: boolean
  employee: {
    first_name: string
    last_name: string
  }
  verification_file_url?: string
}

type DocumentWithEmployee = DocumentFromEndpoint & {
  employee: EmployeeFromEndpoint
  allow_blank: boolean
}

const verificationStatusNotRequiredManual = (value: DocumentVerificationStatus) => {
  if (value !== 'required_manual') return undefined
  return 'You must select a new verification status'
}

const AdminMessageInput = () => (
  <FormDataConsumer<{ verification_status: DocumentVerificationStatus }>>
    {({ formData }) =>
      formData.verification_status === 'non_compliant' ? (
        <TextInput label="Message à envoyer à l'intérimaire" source="admin_verification_message" multiline />
      ) : null
    }
  </FormDataConsumer>
)

const showConfirm = () =>
  // eslint-disable-next-line no-alert
  window.confirm('Êtes-vous sûr de vouloir marquer ce document comme non possédé ? (Le document sera supprimé)')

const DocumentsEditForm = () => {
  const [notOwnedLoading, setNotOwnedLoading] = useState(false)
  const redirect = useRedirect()

  const { record } = useEditContext<DocumentWithEmployee>()
  const [showStatusSelection, setShowStatusSelection] = React.useState(false)

  useEffect(() => {
    if (!record) return

    setShowStatusSelection(record.verification_status === 'required_manual')
  }, [record])

  if (!record) return null

  const onDocumentNotOwnedClick = () => {
    if (showConfirm()) {
      setNotOwnedLoading(true)
      httpClient(`/documents/${record.id}/not_owned_document`, { method: 'delete' })
        .then(() => {
          toast.success('Document marqué comme non possédé')
          redirect('list', 'documents')
        })
        .catch(() => {
          toast.success('Une erreur est survenue lors de la suppression du document')
        })
        .finally(() => {
          setNotOwnedLoading(false)
        })
    }
  }

  const choices = [
    'non_compliant',
    'unreadable',
    'missing_name',
    'missing_double_sided',
    'missing_date',
    'missing_signature',
    'fraudulent',
    record.verifiable_by_ctms ? 'compliant' : 'ok',
  ]

  return (
    <>
      <Title title={`Document ${record.id}`} />
      <Form className="flex justify-between gap-4 p-4">
        <div className="flex flex-col gap-4 p-4 h-fit bg-white rounded-md shadow">
          <Labeled>
            <TextField source="id" />
          </Labeled>
          <Labeled>
            <TextField source="label" />
          </Labeled>
          <Labeled>
            <ReferenceField source="employee.id" reference="employees" label="Employee" link="show" />
          </Labeled>
          <Labeled>
            <ArrayField source="agencies">
              <SingleFieldList resource="agencies" linkType="show">
                <ChipField source="name" size="small" />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
          <Labeled>
            <DateTimeField source="created_at" />
          </Labeled>
          <SelectInput
            id="kind_slug"
            source="kind_slug"
            choices={constants.DOCUMENTS_KIND_SLUGS.map((slug) => ({
              id: slug,
              name: i18next.t(`document_group_config.${slug}`),
            }))}
          />

          <DateInput source="expiration_date" />
          <Labeled label="Current Verification Status">
            <TextField source="verification_status" />
          </Labeled>
          {showStatusSelection ? (
            <>
              <RadioButtonGroupInput
                row={false}
                source="verification_status"
                label="New Verification Status"
                validate={[required(), verificationStatusNotRequiredManual]}
                choices={choices.map((choice) => ({ id: choice, name: i18next.t(`document_groups.status.${choice}`) }))}
              />
              <AdminMessageInput />
            </>
          ) : (
            <Button name="change_verification_status" onClick={() => setShowStatusSelection(true)}>
              Changer le status de vérification
            </Button>
          )}
          {record.verification_file_url && (
            <a
              href={record.verification_file_url}
              aria-label="Certificat"
              rel="noreferrer"
              target="_blank"
              className="flex text-center text-primary"
            >
              Voir le certificat de vérification de CTMS
            </a>
          )}
          {record.allow_blank && (
            <Button
              disabled={notOwnedLoading}
              uiStyle="delete"
              name="change_verification_status"
              onClick={onDocumentNotOwnedClick}
            >
              Document non possedé
            </Button>
          )}
          <SaveButton />
        </div>
        <div className="grow flex justify-end">
          <DocumentViewer document={record} className="h-[calc(100vh-100px)]" mobileDisplayDisabled />
        </div>
      </Form>
    </>
  )
}

export const DocumentsEdit = () => (
  <EditBase mutationMode="pessimistic">
    <DocumentsEditForm />
  </EditBase>
)

export const DocumentsList = () => (
  <List
    sort={DEFAULT_SORT}
    perPage={DEFAULT_PER_PAGE}
    aside={<DocumentFilterSidebar />}
    filters={[
      <SelectInput
        key="1"
        label="Type de document"
        source="kind_slug"
        alwaysOn
        choices={window.document_group_document_configs.map((config) => ({
          id: config.slug,
          name: config.label,
        }))}
      />,
    ]}
    queryOptions={{ refetchInterval: REFETCH_INTERVAL }}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="verification_status" />
      <DateField source="expiration_date" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <FunctionField
        label="Employee"
        render={(record: DocumentWithEmployee) => `${record.employee.first_name} ${record.employee.last_name}`}
      />
    </Datagrid>
  </List>
)
