import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Appsignal from '@appsignal/javascript'
import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network'
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator'
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events'
import constants from 'utils/constants'
import { type Website } from 'types/common'

let appsignal: Appsignal | undefined

const USER_ID_KEY_BY_WEBSITE: Record<Website, string> = {
  admin: 'admin_id',
  employee: 'employee_id',
  agency: 'agent_id',
}

export const addUserId = (userId: string, website: Website) => {
  appsignal?.addDecorator((span) => span.setTags({ [USER_ID_KEY_BY_WEBSITE[website]]: userId }))
}

export const initAppsignal = (website: Website) => {
  appsignal = new Appsignal({
    key: constants.APPSIGNAL_FRONTEND_MONITORING_KEY,
    revision: constants.VERSION,
    namespace: `frontend_${website}`,
  })
  appsignal.use(pluginBreadcrumbsNetwork())
  appsignal.use(pluginPathDecorator())
  appsignal.use(pluginWindowEvents())

  if (window.user_id) {
    addUserId(window.user_id, website)
  }
}

export const addErrorInfo = (key: string, value: string) => {
  appsignal?.addDecorator((span) => span.setTags({ [key]: value }))
}

export const sendError = (error: unknown) => {
  if (error instanceof Error) {
    appsignal?.sendError(error)
  }
}

export const useBreadcrumbsUpdater = () => {
  const location = useLocation()

  useEffect(() => {
    appsignal?.addBreadcrumb({
      category: 'Navigation',
      action: location.pathname,
    })
  }, [location.pathname])
}
