import toast from 'react-hot-toast'
import { sendError } from 'utils/appsignal'
import { QueryCache, QueryClient } from '@tanstack/react-query'

export const getDefaultQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (error) => {
          sendError(error)
          toast.error(
            `Une erreur est survenue. Veuillez réessayer plus tard ou contacter le support. Erreur : ${error.message}`
          )
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        sendError(error)
        toast.error(
          `Une erreur est survenue. Veuillez réessayer plus tard ou contacter le support. Erreur : ${error.message}`
        )
      },
    }),
  })
