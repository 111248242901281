import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Show,
  Edit,
  Create,
  SimpleShowLayout,
  SingleFieldList,
  ReferenceArrayField,
  AutocompleteArrayInput,
  ChipField,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  required,
  email,
  useUnique,
} from 'react-admin'
import { DEFAULT_SORT, DEFAULT_PER_PAGE } from 'admin/utils/react-admin'
import EditToolbar from 'admin/components/EditToolbar'
import constants from 'utils/constants'

export const AgentsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ReferenceArrayField label="Agencies" source="agency_ids" reference="agencies">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
)

export const AgentsList = () => (
  <List sort={DEFAULT_SORT} perPage={DEFAULT_PER_PAGE}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <ReferenceArrayField label="Agencies" source="agency_ids" reference="agencies" sortable={false}>
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

const AgentInputs = () => {
  const unique = useUnique()
  return (
    <>
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
      <TextInput source="email" type="email" validate={[required(), email(), unique()]} />
      <ReferenceArrayInput label="Agencies" source="agency_ids" reference="agencies">
        <AutocompleteArrayInput validate={required()} />
      </ReferenceArrayInput>
    </>
  )
}

export const AgentsCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <AgentInputs />
    </SimpleForm>
  </Create>
)

export const AgentsEdit = () => (
  <Edit mutationMode={constants.IS_TEST_ENV ? 'pessimistic' : 'undoable'}>
    <SimpleForm toolbar={<EditToolbar />}>
      <AgentInputs />
    </SimpleForm>
  </Edit>
)

type Agent = {
  id: string
  first_name: string
  last_name: string
  email: string
  created_at: string
  updated_at: string
}

export const agentsRecordRepresentation = (record: Agent) => `${record.first_name} ${record.last_name} (${record.id})`
