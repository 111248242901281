import React from 'react'
import Modal from 'react-modal'
import { createRoot } from 'react-dom/client'
import { Route } from 'react-router-dom'
import i18next from 'i18next'
import { locale as daysjsLocale } from 'dayjs'
import { Admin, Resource, CustomRoutes } from 'react-admin'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import HailIcon from '@mui/icons-material/Hail'
import FileIcon from '@mui/icons-material/InsertDriveFile'
import ContactMail from '@mui/icons-material/ContactMail'
import { QueryClientProvider } from '@tanstack/react-query'

import { getDefaultQueryClient } from 'utils/react-query'
import { initAppsignal } from 'utils/appsignal'
import Toaster from 'components/Toaster'
import { getDataProvider } from 'admin/utils/react-admin'
import Login from './pages/Login'
import Layout from './pages/Layout'
import {
  AgenciesList,
  AgenciesShow,
  AgenciesEdit,
  AgenciesCreate,
  agenciesRecordRepresentation,
  agencyParamToFormData,
} from './resources/agencies'
import { AgentsList, AgentsShow, AgentsCreate, AgentsEdit, agentsRecordRepresentation } from './resources/agents'
import { DocumentsList, DocumentsEdit } from './resources/documents'
import { EmployeeEdit, employeeRecordRepresentation, EmployeesList, EmployeesShow } from './resources/employees'
import { AgencyReferralsList } from './resources/agency_referrals'
import Stats from './pages/Stats'
import Home from './pages/LoginRedirect'

Modal.setAppElement('#root')

const queryClient = getDefaultQueryClient()

initAppsignal('admin')

// TODO: reload page when logged out
const App = () => {
  if (window.logged_in) {
    return (
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <Admin dataProvider={getDataProvider({ agencies: agencyParamToFormData })} layout={Layout} disableTelemetry>
          <Resource
            name="agencies"
            list={AgenciesList}
            show={AgenciesShow}
            create={AgenciesCreate}
            edit={AgenciesEdit}
            icon={BusinessIcon}
            recordRepresentation={agenciesRecordRepresentation}
          />
          <Resource
            name="agents"
            list={AgentsList}
            show={AgentsShow}
            create={AgentsCreate}
            edit={AgentsEdit}
            icon={PersonIcon}
            recordRepresentation={agentsRecordRepresentation}
          />
          <Resource
            name="employees"
            list={EmployeesList}
            show={EmployeesShow}
            edit={EmployeeEdit}
            icon={HailIcon}
            recordRepresentation={employeeRecordRepresentation}
          />
          <Resource name="documents" list={DocumentsList} edit={DocumentsEdit} icon={FileIcon} />
          <Resource name="agency_referrals" list={AgencyReferralsList} icon={ContactMail} />
          <CustomRoutes>
            <Route path="/stats" element={<Stats />} />
            <Route path="/" element={<Home />} />
          </CustomRoutes>
        </Admin>
      </QueryClientProvider>
    )
  }
  return <Login />
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root')
  if (container) {
    i18next.init({
      lng: 'fr',
      resources: window.translation_keys,
    })
    daysjsLocale('fr')

    const root = createRoot(container)
    root.render(<App />)
  }
})
